import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Header from "../../components/Navbar";
import Footer from "../../components/Footer";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/images/logo.svg";
import {
  CheckAttendee,
  Register_User,
  Register_User_Webinar,
  Update_User_Webinar,
  createPaymentUser,
  getSingleMeeting,
  getSingle_Webinar,
  submitInstancePassword,
} from "../../action/user.action";
import { toastAlert } from "../../utils/toast";
import { useNavigate, useParams } from "react-router-dom";
import { decryptString, isEmpty } from "../../helper/helper";
import { Link } from "react-router-dom";
import config from "../../lib/config";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Alert, Button, Modal, Row, Col } from "react-bootstrap";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  createPayment,
  getPakageLists,
  getSubcriptionLists,
  updatePakageLists,
} from "../../action/user.action";
import { toast } from "react-toastify";

const InitialFormValue = {
  email: "",
  username: "",
  acknowledgement: false,
  Lastname: "",
  phone: "",
  password: "",
};
function UserRegister() {
  //payment
  const stripe = useStripe();
  const elements = useElements();
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const { id } = useParams();
  console.log(id, "id");

  const [initialState, setinitialState] = useState({});

  // const [webinae_details, setWebinar_details] = useState()

  const getMeetingDetails = async () => {
    try {
      let meeting_id = await decryptString(id, true);

      console.log(meeting_id, "meeting_id");

      const payload = JSON.parse(meeting_id);

      // return
      // const payload = {
      //     id: meeting_id
      // }
      const { status, result } = await getSingle_Webinar(payload);

      console.log(status, result, "getSingle_Webinar");
      if (status) {
        setinitialState(
          result?.result?.length > 0 ? result?.result?.[0] : result?.result
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getMeetingDetails();
    }
  }, []);

  const [formValue, setFormValue] = useState(InitialFormValue);

  const [showconfPwd, setShowconfPwd] = useState(false);

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [error, setError] = useState();
  const [attendeeId, setAttendeeId] = useState();
  const [paymentStatus, setPaymentStatus] = useState(0);
  const [attendeeUrl, setAttendeeUrl] = useState();
  const [fieldDisable, setFieldDisable] = useState(false);

  const navigate = useNavigate();

  const { email, username, acknowledgement, Lastname, phone } = formValue;

  const changesHadler = async (e) => {
    try {
      const { id, value } = e.target;
      console.log(id, value);
      if (id == "acknowledgement") {
        setFormValue({ ...formValue, [id]: true });
      } else {
        setFormValue({ ...formValue, [id]: value });
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(formValue, "formValue");

  const validation = async (value) => {
    const emailRegex = /[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/;

    // const disposableDomains = [
    //   'yopmail.com', 'tempmail.com', 'mailinator.com', 'guerrillamail.com',
    //   '10minutemail.com', 'getnada.com', 'trashmail.com', 'throwawaymail.com'
    // ];

    let error = {};
    if (isEmpty(value.username)) {
      error.username = "Username is required";
    }
    if (isEmpty(value.email)) {
      error.email = "Email is required";
    } else if (!emailRegex.test(value.email)) {
      error.email = "Invalid email address";
    }
    // else {
    //   const domain = value.email.split('@')[1];
    //   if (disposableDomains.includes(domain)) {
    //     error.email = "Disposable email addresses are not allowed.";
    //   }
    // }
    if (!value.acknowledgement) {
      error.acknowledgement = "Acknowledgement is required";
    }

    if (
      initialState.masterPasword != "" &&
      isEmpty(value.password) &&
      isEmailSent
    ) {
      error.password = "Password is required";
    }

    if (initialState.Lastname && isEmpty(value.Lastname)) {
      error.Lastname = "Lastname is required";
    }
    if (initialState.phone && isEmpty(value.phone)) {
      error.phone = "Phonenumber is required";
    } else if (!/^\d{10}$/.test(value.phone)) {
      error.phone = "Invalid phone number. It should be 10 digits long.";
    }
    return error;
  };


  console.log(initialState, "acknowledgement");

  const submitpassword = async () => {
    try {
      console.log(formValue, "sssssssssssss");

      const dat = await validation(formValue);

      if (isEmpty(dat)) {
        setError({});
        const { status, result } = await submitInstancePassword(formValue);
        console.log(status, result, "status, result");
        if (status) {
          if (result.AttendeeUrl) {
            window.location.href = result.AttendeeUrl;
            toastAlert("success", result.message);
          } else {
            toastAlert("success", result.message);
          }
        } else {
          toastAlert("error", result.message);
          setTimeout(() => {
            getMeetingDetails();
          }, 1000);
        }
      } else {
        setError(dat);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [gForm, setGfrom] = useState("");

  useEffect(() => {
    if (initialState?.gFormurl) {
      setGfrom(initialState.gFormurl);
    }
  }, [initialState?.gFormurl]);


  const viewGoogleForm = async () => {
    setShowG(true);
  };

  const [show, setShow] = useState(false);
  const [showG, setShowG] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseG = () => setShowG(false);
  const handleShow = () => setShow(true);

  // const handleFormLoad = (e) => {
  //   var iframe = document.getElementById("testframe");
  //   //var elmnt = iframe.contentWindow.document.getElementsByTagName("form")[0];
  //   console.log("element:=", iframe.contentWindow.document);
  // };


  const iframeRef = useRef(null);
  const [submittedUrl, setSubmittedUrl] = useState("");

  const handleFormLoad = () => {
    console.log("Loop -- 1")
    try {
      let a = document.getElementById('google-form')
      console.log(a, 'kerkwerkwekr')
      if (a) {
        let iframeDocumentUrl = a.contentWindow.document.location.href;
        console.log("asajsbdjsa", iframeDocumentUrl)
        let targetElement = a.querySelector('vHW8K');
        console.log(targetElement, 'kesasasaasassar');
      }
    } catch (error) {
      console.error("Cross-origin access error:", error);
    }
  };

  // Register fees (payment button)
  const payment = async (e, payment) => {
    if (await submit_Handler(e, payment)) {
      setShowModal(true);
    }
  };

  // confim and payment
  const handleSubmit = async (amo) => {
    try {
      if (!stripe || !elements) {
        toast.error("Stripe is not loaded. Please refresh.");
        return;
      }

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        toast.error("Payment card details not found");
        return;
      }

      // Request backend to create a Payment Intent
      const data = { amount: amo, currency: "inr" };
      const { status, result } = await createPaymentUser(data);

      if (!status || !result?.clientSecret) {
        toast.error("Failed to initiate payment. Try again.");
        return;
      }

      const clientSecret = result.clientSecret;

      // Confirm payment with Stripe
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: "Pandiess", // Replace with actual user name
          },
        },
      });

      if (error) {
        console.error("Payment failed:", error.message);
        toast.error(`Payment failed: ${error.message}`);
        return;
      }

      if (paymentIntent.status === "succeeded") {
        // Update user payment status
        formValue.pay_status = 2;
        formValue.attendeeId = attendeeId;

        const { status, result } = await Update_User_Webinar(formValue);
        console.log("payment:=", result)
        if (status) {
          setPaymentStatus(result?.user?.payment_status);
          setShowModal(false);
          toast.success("Payment successful!");
        }
        else {
          toast.success("Payment status not update into the user!");
        }
      }
      else {
        formValue.pay_status = 1;
        formValue.attendeeId = attendeeId;
        const { status, result } = await Update_User_Webinar(formValue);
        if (status) {
          setPaymentStatus(result?.user?.payment_status);
          setPaymentStatus(1);
          toast.success("Payment failed!");
        }
        else {
          toast.success("Payment status not update into the user!");
        }
      }
      setFieldDisable(true);
    } catch (err) {
      console.error("Error during payment:", err);
      toast.error("An unexpected error occurred.");
    }
  };


  // User Register Handler
  const submit_Handler = async (e, payment) => {
    e.preventDefault();
    try {
      // Instant meeting validation
      if (initialState?.mettingType === 0) {
        const webinarDetails = initialState?.webinar_Details?.[0];
        if (webinarDetails?.status === 2) {
          toastAlert("error", "This session is already completed.");
          return false;
        }
      }

      // Validate form data
      const validationErrors = await validation(formValue);
      if (!isEmpty(validationErrors)) {
        setError(validationErrors);
        return;
      }

      setError({});
      let meeting_id = await decryptString(id, true);
      meeting_id = JSON.parse(meeting_id);

      // Prepare form data
      formValue.id = initialState._id;
      formValue.type = meeting_id.type;

      // Add session ID for webinar type meetings
      if (meeting_id.type === 1) {
        formValue.sessionid = initialState.Session_id;
      }

      // Submit data to the backend
      const { status, result } = await Register_User_Webinar(formValue);
      console.log("Response:", status, result);
      if (result?.AttendeeID) {
        setAttendeeId(result?.AttendeeID);
      }
      if (status) {
        setAttendeeUrl(result?.AttendeeUrl);
        if (!payment) {
          if (result?.AttendeeUrl) window.open(attendeeUrl, "_self");
        }
        return true;
      }
      else {
        if (!payment) {
          setFieldDisable(true);
          toast.error(result?.message);
        }
        return true;
      }
    } catch (error) {
      console.error("Error in submit_Handler:", error);
    }
  };


  const joinMeeting = async (e) => {
    e.preventDefault();

    /*
      Webinar - model:

      configType: {
        type: Number,
        required: true, // 0 -Express 1-Full
      }

      mettingType: {
        type: Number,
        required: true, // 0 - rightNow 1- Sceduled for later 2 - Always On
      }
    */
    if (initialState?.configType == 1 && initialState?.mettingType == 1) {
      toast.success("Link sent to your mail.");
    }
    else {
      try {
        // Validate form data
        const validationErrors = await validation(formValue);
        if (!isEmpty(validationErrors)) {
          setError(validationErrors);
          return;
        }

        setError({});
        let meeting_id = await decryptString(id, true);
        meeting_id = JSON.parse(meeting_id);
        console.log("meeting===", initialState)

        // Prepare form data
        formValue.id = initialState._id;
        formValue.type = meeting_id.type;

        // Add session ID for webinar type meetings
        if (meeting_id.type === 1) {
          formValue.sessionid = initialState.Session_id;
        }

        // Submit data to the backend
        const { status, result } = await Register_User_Webinar(formValue);
        if (status) {
          if (result?.AttendeeUrl) window.open(attendeeUrl, "_self");
        }
        else {
          toast.error(result?.message);
        }
      } catch (error) {
        console.error("Error in submit_Handler:", error);
      }
    }
  }


  return (
    <>
      <div className="instant-meeting  register_page">
        <Container>
          <div className="instant-meeting-head">
            <div className="mt-5 mb-5">
              <a href="/">
                <img
                  src={logo}
                  className="img-fluid brand_logo mx-auto d-block"
                  alt="logo"
                />
              </a>
            </div>
            <div className="white-box instant-meeting-box">
              {initialState.status == 2 ? (
                <Alert key={"primary"} className="p-2 alert_email">
                  <i className="me-2 fa fa-info-circle"></i>
                  This session has been completed. We've covered all the
                  necessary topics and addressed your questions. If you have any
                  further inquiries or need additional assistance, feel free to
                  reach out. Thank you for your time, and have a great day!
                  &nbsp;&nbsp;
                </Alert>
              ) : (
                ""
              )}
              <div className="white-box">
                {/* <div>
                  <img
                    src={
                      initialState?.presenterTempImage ||
                      initialState?.presenterTempImage != ""
                        ? `${config.API_URL}/presenter/${initialState?.presenterTempImage}`
                        : require("../../assets/images/profile-img2.png")
                    }
                    className="img-fluid profile-img-width"
                  />{" "}
                  <h6>{initialState?.Presenters?.[0].Presenters_name}</h6>
                </div>
                <div className="invite-temp">
                  <p>You have been invited to join</p>
                  <h6>{initialState?.title}</h6>
                  <p>{initialState?.description}</p>
                </div> */}

                <Row className="align-items-center">
                  <Col lg="3" md="3" sm="4" className="p-0">
                    {" "}
                    <div className="mx-auto d-block">
                      <img
                        src={
                          initialState?.presenterTempImage ||
                            initialState?.presenterTempImage != ""
                            ? `${config.API_URL}/presenter/${initialState?.presenterTempImage}`
                            : require("../../assets/images/profile-img2.png")
                        }
                        className="img-fluid profile-img-width mx-auto d-block"
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                  </Col>
                  <Col lg="9" md="9" sm="8" className="p-0">
                    <div className="invite-temp">
                      <p>You have been invited to join</p>
                      <h6>{initialState?.title}</h6>
                      <p>{initialState?.description}</p>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="invite_details_head">
                <div className="invite_details">
                  <div className="text-box">
                    <label for="exampleFormControlInput1" class="form-label">
                      User Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="error_input">
                      <input
                        type="text"
                        class="form-control"
                        id="username"
                        placeholder="Enter your username"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={username}
                        onChange={changesHadler}
                        disabled={initialState.status == 2 || fieldDisable}
                      />
                      <div className="error_msg ">
                        {error && error.username ? (
                          <span style={{ color: "red" }}>{error.username}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  {initialState.Lastname && (
                    <div className="text-box">
                      <label for="exampleFormControlInput1" class="form-label">
                        Last Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="error_input">
                        <input
                          type="text"
                          class="form-control"
                          id="Lastname"
                          placeholder="Enter your Lastname"
                          value={Lastname}
                          onChange={changesHadler}
                          disabled={initialState.status == 2 || fieldDisable}
                        />
                        <div className="error_msg ">
                          {error && error.Lastname ? (
                            <span style={{ color: "red" }}>
                              {error.Lastname}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="text-box">
                    <label for="exampleFormControlInput1" class="form-label">
                      Email address <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="error_input">
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={changesHadler}
                        disabled={initialState.status == 2 || fieldDisable}
                      />
                      <div className="error_msg ">
                        {error && error.email ? (
                          <span style={{ color: "red" }}>{error.email}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  {initialState.phone && (
                    <div className="text-box">
                      <label for="exampleFormControlInput1" class="form-label">
                        Phone Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="error_input">
                        <input
                          type="number"
                          pattern="[0-9]*"
                          class="form-control"
                          id="phone"
                          placeholder="Enter your Phone Number"
                          value={phone}
                          onChange={changesHadler}
                          disabled={initialState.status == 2 || fieldDisable}
                        />
                        <div className="error_msg ">
                          {error && error.phone ? (
                            <span style={{ color: "red" }}>{error.phone}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {initialState.registrationFees != 0 && (
                  <div className="text-box">
                    <label for="exampleFormControlInput1" class="form-label">
                      Registration Fees <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="username"
                      placeholder="Enter the amount"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={initialState.registrationFees}
                      disabled
                    // onChange={changesHadler}
                    />
                  </div>
                )}

                <div class="form-check formcheck_register">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="acknowledgement"
                    onChange={changesHadler}
                  />

                  <label class="form-check-label" for="acknowledgement">
                    <span style={{ color: "red" }}>*</span> I acknowledge that
                    this session may be recorded. This may include my voice and
                    video if enabled.
                  </label>
                  <br></br>
                  {error && error.acknowledgement ? (
                    <span style={{ color: "red" }}>
                      {error.acknowledgement}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {initialState.status == 2 ? (
                  <Link
                    to="#"
                    className="primary_btn"
                  // onClick={submit_Handler}
                  >
                    Meeting Completed
                  </Link>
                ) : (
                  <>
                    {isEmailSent ? (
                      <>
                        <div className="text-box">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Password <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="inner-accMain">
                            <InputGroup className="mb-3 input-box-1 ">
                              <Form.Control
                                type={showconfPwd ? "text" : "password"}
                                // style={{border}}

                                class="form-control"
                                id="password"
                                placeholder="Enter your Password"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                // value={initialState.password}
                                // disabled
                                onChange={changesHadler}
                                disabled={initialState.status == 2}
                              />

                              <InputGroup.Text id="basic-addon2">
                                {" "}
                                {showconfPwd ? (
                                  <i
                                    className="fa-regular fa-eye"
                                    // style={{
                                    //   marginTop: "13px",
                                    //   marginRight: "15px"
                                    // }}

                                    onClick={() => setShowconfPwd(false)}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa-regular fa-eye-slash"
                                    // style={{
                                    //   marginTop: "13px",
                                    //   marginRight: "15px"
                                    // }}
                                    onClick={() => setShowconfPwd(true)}
                                  ></i>
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          {error && error.password ? (
                            <span style={{ color: "red" }}>
                              {error.password}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <Link
                          to="#"
                          className="primary_btn"
                          onClick={submitpassword}
                        >
                          Submit Password
                        </Link>
                      </>
                    ) : (
                      <div className="instant-btn">
                        {initialState.configType == 0 ? (
                          <>
                            {initialState.sessionType == 0 ? (
                              <>
                                <Link
                                  to="#"
                                  className="primary_btn2 me-3 mb-3"
                                  onClick={(e) => submit_Handler(e, false)}
                                >
                                  Join Meeting
                                </Link>
                                {gForm && (
                                  <Link
                                    to="#"
                                    className="primary_btn mb-3"
                                    onClick={viewGoogleForm}
                                  >
                                    Google Form Register
                                  </Link>
                                )}
                              </>
                            ) : (
                              <>
                                {!initialState.registrationFees ? (<Link
                                  to="#"
                                  className="primary_btn mb-3 mb-3"
                                  onClick={(e) => submit_Handler(e, false)}
                                >
                                  Register
                                </Link>) : (
                                  <>
                                    {(paymentStatus == 2) ? (
                                      <Link
                                        to="#"
                                        className="primary_btn mb-3 mb-3"
                                        onClick={(e) => joinMeeting(e)}
                                      >
                                        Join Meeting
                                      </Link>
                                    ) : (
                                      <Link
                                        to="#"
                                        className="primary_btn mb-3 mb-3"
                                        onClick={(e) => payment(e, true)}
                                      >
                                        Payment
                                      </Link>
                                    )}
                                  </>
                                )}

                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {!initialState.registrationFees ? (<Link
                              to="#"
                              className="primary_btn mb-3 mb-3"
                              onClick={(e) => submit_Handler(e, false)}
                            >
                              Register
                            </Link>) : (
                              <>
                                {(paymentStatus == 2) ? (
                                  <Link
                                    to="#"
                                    className="primary_btn mb-3 mb-3"
                                    onClick={(e) => joinMeeting(e)}
                                  >
                                    Join Meeting
                                  </Link>
                                ) : (
                                  <Link
                                    to="#"
                                    className="primary_btn mb-3 mb-3"
                                    onClick={(e) => payment(e, true)}
                                  >
                                    Payment
                                  </Link>
                                )}
                              </>
                            )}
                            {gForm && (
                              <Link
                                to="#"
                                className="primary_btn mb-3"
                                onClick={viewGoogleForm}
                              >
                                Google Form Register
                              </Link>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}

                {/* } */}
                {/* <div className="instant-login">
                                    <Link>
                                        Already have an account?<span>Login</span>
                                    </Link>
                                </div> */}
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        className="finish_model register_model"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className=" instant-meeting-head">
            <div className=" instant-meeting-box completed_meeting">
              {/* <div className="white-box">
                            <div>

                            </div> */}
              <center></center>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <p>Webinar Session Has Completed</p> */}
                <img
                  src={require("../../assets/images/group.png")}
                  className="img-fluid"
                />{" "}
              </div>
              <div className="finishpage mt-3">
                <p>
                  You have successfully registered for the webinar. The link to
                  join the webinar has been sent to your registered email
                  address.
                </p>
              </div>
              {/* <center>
                <button className="primary_btn mt-3">Go To Webinar List</button>
              </center> */}
              {/* </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* GoogleForm */}

      <Modal
        show={showG}
        onHide={handleCloseG}
        size="lg"
        className="email-model form-model"
      >
        <Modal.Header className="model-head" closeButton>
          <Modal.Title className="model-title">Fill the form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {gForm.length > 0 ? (
            <iframe
              ref={iframeRef}
              id="google-form"
              // referrerPolicy="origin"
              src={gForm}
              //src="https://docs.google.com/forms/d/e/1FAIpQLSeJzfV-a2G5cFi92O2RDFQoVePRF7k1JzVOL1yEnzwzlq6gzw/viewform?embedded=true"
              width="100%"
              height="100%"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>
          ) : (
            <div className="support_empty">
              <img
                src={require("../../assets/images/inner_support_img.png")}
                className="img-fluid pe-2"
              />
              <p>No Form</p>
            </div>
          )}
        </Modal.Body>
      </Modal>


      {/* Modal for Card Payment */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="email-model"
      >
        <Modal.Header closeButton className="model-head">
          <Modal.Title className="model-title">
            {" "}
            Payment for Register Fees
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label">Enter your card details:</label>
          <CardElement />

        </Modal.Body>
        <Modal.Footer className="footer-model">
          <Button className="primary_btn2" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            className="primary_btn"
            onClick={() =>
              handleSubmit(
                initialState.registrationFees
              )
            }
          >
            Confirm and Pay
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <div className="nav_sec">
                <Header />
            </div> */}

      {/* <Footer /> */}
    </>
  );
}

export default UserRegister;