import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal"; // Import Modal
import { Form, Button, InputGroup, Col, Row, Image } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Header from "../components/Navbar";
import Innerfooter from "../components/inner-footer";
import Innerpagenavbar from "../components/innerpage-navbar";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  createPayment,
  getPakageLists,
  getSubcriptionLists,
  updatePakageLists,
} from "../action/user.action";
import key from "../lib/config";
import { toastAlert } from "../utils/toast";

const Subscription = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentStatus, setPaymentStatus] = useState("");
  const [pakages, setPakages] = useState([]);
  const [options, setOptions] = useState([]);
  const [subcription, setSubcription] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [updatelist, setUpdatelist] = useState([]);

  const handleSubmit = async (amo, days, id, type) => {
    console.log("hii", id);
    if (!stripe || !elements) {
      return; 
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      alert("CardElement not found");
      return;
    }

    console.log("cardElement", cardElement);

    const data = {
      amount: amo,
      currency: "inr",
      noOfDays: days,
    };

    const { status, result } = await createPayment(data);
    let clientSecret = "";
    console.log("status47", status);
    console.log("clientSecret", result);
    if (status) {
      clientSecret = result?.clientSecret;
    } else {
      toastAlert("error", result.message);
    }

    if (clientSecret !== "") {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );

      console.log("paymentIntent", paymentIntent);

      if (error) {
        setPaymentStatus(`Payment failed: ${error.message}`);
        console.log(`Payment failed: ${error.message}`);
      } else if (paymentIntent.status === "succeeded") {
        setPaymentStatus("Payment successful!");

        const data = {
          planId: id,
          noOfDays: days,
          amount: amo,
          currency: "inr",
          type
        };
        const { status, result } = await updatePakageLists(data);

        console.log("result", result);
        toastAlert("success", result?.message);
        setUpdatelist(result.updatedPackage);

        setShowModal(false); // Close modal on successful payment
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    getPakageList();
    getSubcriptionList();
  }, []);

  const getPakageList = async () => {
    const { status, result } = await getPakageLists();
    if (status) {
      setPakages(result.result);
      setOptions(result.result1)
    }
  };
  console.log("options", options)

  const getSubcriptionList = async () => {
    const { status, result } = await getSubcriptionLists();
    if (status) {
      console.log("resultsub", result?.result);
      setSubcription(result?.result);
    }
  };

  //Month Order Page
  const handlePackageSelect = (pkg) => {
    const pkg1 = {
      package_amount: pkg?.package_Mamount,
      package_month: 1,
      _id: pkg?._id,
      type: 'Month'
    }
    setSelectedPackage(pkg1);
    setShowModal(true);
  };
  //Year Order Page
  const handleYearPackageSelect = (pkg) => {
    const pkg1 = {
      package_amount: pkg?.package_Yamount,
      package_month: 12,
      _id: pkg?._id,
      type: 'Year'
    }
    setSelectedPackage(pkg1);
    setShowModal(true);
  };
  console.log("updatelist", updatelist);

  console.log("subcription?.planID", subcription);

  console.log("pakages?._id", pakages);

  const subcriptionId = subcription.map((item) => item.planID);

  const subscriptionType = subcription.map((item) => item.typeMode);

  console.log("subcriptionId000000", subcriptionId,subscriptionType);

  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>
      <div className="dashboard-page">
        <div className="container">
          <div className="page-flex">
            <div className="page-left">
              <Innerpagenavbar />
            </div>
            <div className="page-right">
              <div className="dashbord-right Subscription-right">
                <div className="head">
                  <h2>Subscription plan</h2>
                  <p>
                    Choose the plan that fits your needs, and experience the
                    full power of Vaffix for the next 30 or 360 days!
                  </p>
                </div>
                <div>
                  <ul
                    class="nav nav-tabs subscription-tab"
                    id="myTab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Monthly
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Annual
                        <br></br>
                        <span>Up to 24% discount</span>
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div>
                        <Row>
                          {pakages?.map((pkg) => (
                            <Col xl="4" lg="8" md="8" sm="8" className="mb-5" key={pkg._id}>
                              <div
                                className={`subscription-box ${subcriptionId == pkg._id && subscriptionType == 'Month'
                                  ? "subscription-box-batch"
                                  : ""
                                  }`}
                              >
                                {pkg.package_name === "Standard Package" && (
                                  <div className="batch">Best Value</div>
                                )}
                                <div className="content1">
                                  <h4 className="">{pkg.package_name}</h4>
                                  {/* <h6>{pkg.attendee_limit} Attendees</h6> */}
                                  <h1>
                                    ${pkg.package_Mamount}<span>/mo</span>
                                  </h1>
                                  <p>(billed monthly)</p>
                                  {/* <h4>${pkg.package_Yamount}/Year</h4> */}
                                  <p>Annual billing saves <span>${(pkg.package_Mamount * 12) - pkg.package_Yamount}</span></p>
                                  {/* <button className="primary_btn">Get Started</button> */}
                                </div>
                                <div className="content2">
                                  <ul>
                                    {(() => {
                                      const enabledOptions = options.filter(option =>
                                        pkg.planoption.some(packageItem => packageItem.label === option.label)
                                      )
                                      const disabledOptions = options.filter(option =>
                                        !pkg.planoption.some(packageItem => packageItem.label === option.label)
                                      )
                                      return (
                                        <>
                                          {enabledOptions.map(option => (
                                            <li key={option._id}>
                                              {option.label}
                                            </li>
                                          ))}
                                          {disabledOptions.map(option => (
                                            <li key={option._id} className="disable_li">
                                              {option.label}
                                            </li>
                                          ))}
                                        </>
                                      );
                                    })()}
                                  </ul>
                                </div>
                                <div className="content3">
                                  <button className="primary_btn"
                                    onClick={() => handlePackageSelect(pkg)}>Get Started</button>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div>
                        <Row>
                          {pakages?.map((pkg) => (
                            <Col xl="4" lg="8" md="8" sm="8" className="mb-5" key={pkg._id}>
                              <div
                                className={`subscription-box ${subcriptionId == pkg._id && subscriptionType == 'Year'
                                  ? "subscription-box-batch"
                                  : ""
                                  }`}
                              >
                                {pkg.package_name === "Standard Package" && (
                                  <div className="batch">Best Value</div>
                                )}
                                <div className="content1">
                                  <h4 className="">{pkg.package_name}</h4>
                                  {/* <h6>{pkg.attendee_limit} Attendees</h6> */}
                                  <h1>
                                    ${parseInt(pkg.package_Yamount / 12)}<span>/mo</span>
                                  </h1>
                                  <p>(billed annually)</p>
                                  {/* <h4>${pkg.package_Yamount}/Year</h4> */}
                                  <p>Save <span>${(pkg.package_Mamount * 12) - pkg.package_Yamount}</span> a year</p>
                                  {/* <button className="primary_btn">Get Started</button> */}
                                </div>
                                <div className="content2">
                                  <ul>
                                    {(() => {
                                      const enabledOptions = options.filter(option =>
                                        pkg.planoption.some(packageItem => packageItem.label === option.label)
                                      )
                                      const disabledOptions = options.filter(option =>
                                        !pkg.planoption.some(packageItem => packageItem.label === option.label)
                                      )
                                      return (
                                        <>
                                          {enabledOptions.map(option => (
                                            <li key={option._id}>
                                              {option.label}
                                            </li>
                                          ))}
                                          {disabledOptions.map(option => (
                                            <li key={option._id} className="disable_li">
                                              {option.label}
                                            </li>
                                          ))}
                                        </>
                                      );
                                    })()}
                                  </ul>
                                </div>
                                <div className="content3">
                                  <button className="primary_btn"
                                    onClick={() => handleYearPackageSelect(pkg)}>Get Started</button>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                <p>{paymentStatus}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Card Payment */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="email-model"
      >
        <Modal.Header closeButton className="model-head">
          <Modal.Title className="model-title">
            {" "}
            Payment for {selectedPackage?.package_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label">Enter your card details:</label>
          <CardElement />
        </Modal.Body>
        <Modal.Footer className="footer-model">
          <Button className="primary_btn2" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            className="primary_btn"
            onClick={() =>
              handleSubmit(
                selectedPackage.package_amount,
                selectedPackage.package_month * 30,
                selectedPackage._id,
                selectedPackage.type
              )
            }
          >
            Confirm and Pay
          </Button>
        </Modal.Footer>
      </Modal>

      <Innerfooter />
    </>
  );
};

export default Subscription;
