let key = {};

let Envname = "live";
if (Envname == "live") {
  key = {
    API_URL: `https://prodapi.vaffix.com`,
    front_URL: "https://www.vaffix.com",
    BBB_URL: "https://meetings.vaffix.com",
    Recaptchakey_sitekey: "6LdeZAgqAAAAAD53GmKAAgAUmLtJAxx7AfBye-op",
    Recaptchakey_sectrykey: "6LdeZAgqAAAAAGJiVPsksfHmBRWKX2WOzy5bXY9q",
    stripe:
      "pk_test_51OjxfWSCUxXzYhK4aCbzWhrmDT1a5sUvEMoa2la4XkVWGOLQ3DH0wrG3bIM52Pyy5bkX0Rg809OSKIeOrshKQlTu00eNYhoNQ4",
    getGeoInfo: "https://ipapi.co/json/",
    GoogleOauth:
      "861891002950-37avkj3e9l37j14mrt251p41jn48is5f.apps.googleusercontent.com",
    FaceBookID: "1105572426750654",
    googleClientID:
      "739641037356-67t5fgunfv6909u5s0ushu1p88bmnui5.apps.googleusercontent.com",
    googleCalendarScopes: "https://www.googleapis.com/auth/calendar",
    STRIPE_PUBLIC_KEY:
      "pk_test_51MYS97SCYFu9pKoCjfrpbFkCwyyorr7wn8tcpQVrG7ZoyeDvc8oFqVbFWD66euVgYLZc1ii4YGZL5i2rYjPNz49g00hi0ZQaiI",
    // STRIPE_PUBLIC_KEY:
    //     "pk_test_51QKBwzFwZftb2hZRU745HJLwfDcjmnoNst1g6BbZgtKZOeaelFKF6UDJ0aBRMc6ERyAIiIlHp9o7asHJDzoC8wVj00C9sW6x7T",
  };
} else {
  if (Envname == "demo") {
    // const API_URL = "http://localhost";
    key = {
      API_URL: `https://vaffixdemoapi.wearedev.team`,
      front_URL: "https://vaffix-frontend-2024.pages.dev",
      BBB_URL: "https://meetings.vaffix.com",
      Recaptchakey_sitekey: "6LdeZAgqAAAAAD53GmKAAgAUmLtJAxx7AfBye-op",
      Recaptchakey_sectrykey: "6LdeZAgqAAAAAGJiVPsksfHmBRWKX2WOzy5bXY9q",
      stripe:
        "pk_test_51OjxfWSCUxXzYhK4aCbzWhrmDT1a5sUvEMoa2la4XkVWGOLQ3DH0wrG3bIM52Pyy5bkX0Rg809OSKIeOrshKQlTu00eNYhoNQ4",
      getGeoInfo: "https://ipapi.co/json/",
      GoogleOauth:
        "861891002950-37avkj3e9l37j14mrt251p41jn48is5f.apps.googleusercontent.com",
      FaceBookID: "1105572426750654",
      googleClientID:
        "739641037356-67t5fgunfv6909u5s0ushu1p88bmnui5.apps.googleusercontent.com",
      googleCalendarScopes: "https://www.googleapis.com/auth/calendar",
      STRIPE_PUBLIC_KEY:
        "pk_test_51MYS97SCYFu9pKoCjfrpbFkCwyyorr7wn8tcpQVrG7ZoyeDvc8oFqVbFWD66euVgYLZc1ii4YGZL5i2rYjPNz49g00hi0ZQaiI",
      // STRIPE_PUBLIC_KEY:
      //   "pk_test_51QKBwzFwZftb2hZRU745HJLwfDcjmnoNst1g6BbZgtKZOeaelFKF6UDJ0aBRMc6ERyAIiIlHp9o7asHJDzoC8wVj00C9sW6x7T",
    };
  } else {
    const API_URL = "http://localhost";
    key = {
      API_URL: `${API_URL}:3040`,
      front_URL: "http://localhost:3000",
      BBB_URL: "https://meetings.vaffix.com",
      Recaptchakey_sitekey: "6LeZWfYpAAAAAH742Zxt35gkeIA6tdQ3gE7ylh_Z",
      Recaptchakey_sectrykey: "6LeZWfYpAAAAAApKWjSPzOz_SlxodqXK0GPKn6Vm",
      // stripe:"pk_test_51QKBwzFwZftb2hZRU745HJLwfDcjmnoNst1g6BbZgtKZOeaelFKF6UDJ0aBRMc6ERyAIiIlHp9o7asHJDzoC8wVj00C9sW6x7T",
      stripe:
        "pk_test_51OjxfWSCUxXzYhK4aCbzWhrmDT1a5sUvEMoa2la4XkVWGOLQ3DH0wrG3bIM52Pyy5bkX0Rg809OSKIeOrshKQlTu00eNYhoNQ4",
      getGeoInfo: "https://ipapi.co/json/",
      GoogleOauth:
        "861891002950-37avkj3e9l37j14mrt251p41jn48is5f.apps.googleusercontent.com",
      FaceBookID: "1105572426750654",
      googleClientID:
        "739641037356-67t5fgunfv6909u5s0ushu1p88bmnui5.apps.googleusercontent.com",
      googleCalendarScopes: "https://www.googleapis.com/auth/calendar",
      STRIPE_PUBLIC_KEY:
        "pk_test_51QKBwzFwZftb2hZRU745HJLwfDcjmnoNst1g6BbZgtKZOeaelFKF6UDJ0aBRMc6ERyAIiIlHp9o7asHJDzoC8wVj00C9sW6x7T",
    };
  }
}

export default key;
