import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Form, Button, InputGroup, Col, Row, Image, Spinner } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Navinner from "../components/nav-inner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import nextImg from "../assets/images/side-icon.svg";
import nextImg1 from "../assets/images/side-icon2.svg";
import { clearCookies } from "../utils/cookies";

import Header from "../components/Navbar";
import Innerfooter from "../components/inner-footer";

import Innerpagenavbar from "../components/innerpage-navbar";
import Settingpage from "../components/dashboard/settingpage";
import Webinarpage from "./express/schudule/webinar";
import {
  Create_Webinar,
  checkBankDetails,
  connectAccount,
  create_Meeting,
  delete_Webinar,
  disconnectAccount,
  fetchStripeStatus,
  searchMeetings,
  sendOtpEmail,
  updateUserDeatails,
  updateYoutube_key,
  user_resetPassword,
  verifyOtp,
} from "../action/user.action";
import { useDispatch, useSelector } from "react-redux";
import { encryptString, isEmpty } from "../helper/helper";
import {
  Search_Details,
  setWebinar,
  Webinar_Details,
} from "../redux/features/webinar_details";

import moment, { now } from "moment";

import config from "../lib/config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faShareFromSquare,
  faMagnifyingGlass,
  faListUl,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import Emptymeeting from "../assets/images/Emptymeeting.png";

//COMPONENTS
import ConfirmationModal from "../components/common/modals/ConfirmationModal";
import { toastAlert } from "../utils/toast";
import PropagateLoader from "react-spinners/PropagateLoader";

import { formatInTimeZone } from "date-fns-tz";
import { getUserDetails } from "../redux/features/userdetails";
import key from "../lib/config";
import { getSubcriptionLists } from "../action/user.action";

const initalform = {
  facebookkey: "",
  youtubekey: "",
  paymentkey: "",
  privatekey: "",
  telegramkey: "",
  firstname: "",
  lastname: "",
  emailid: "",
  otp: "",
  profile_image: null,
};

const InnerSettings = () => {
  const user_deatils = useSelector((state) => state.User);

  const dispatch = useDispatch();
  console.log(user_deatils, "user_deatils");

  //stripe
  const [stripeAccountId, setStripeAccountId] = useState();
  const [bankConnected, setBankConnected] = useState(false);
  const [stripeLoader, setStripeLoader] = useState(false);


  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const confirmationConfig = {
    title: "Disconnect Account",
    message: "Are you sure want to disconnect the Account?",
  };


  const [initialValue, setintialValue] = useState(initalform);
  const [streamKey, setStreamKey] = useState({
    youtubekey: "",
    facebookkey: "",
    telegramkey: "",
    paymentkey: "",
    privatekey: "",
  });
  const [isOTPSent, setIsOtpSent] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [timer, setTimer] = useState(300);
  const [error, setError] = useState();
  const [isDisableResend, setIsDisableResend] = useState(false); // Disable resend button state
  const [resendTimer, setResendTimer] = useState(120); // 2-minute resend disable timer
  const [previewUrl, setpreviewUrl] = useState("");
  const fileRef = useRef();
  const [values, setValues] = useState([]);
  const [edit_presenter, setEdit_Presenter] = useState(true);
  const [newPresenter, setnewPresenter] = useState({
    preview: "",
    profile_image: "",
    Presenters_name: "",
    Presenters_email: "",
    edit: false,
  });
  const [subcription, setSubcription] = useState([]);
  const [loader, setLoader] = useState(false);

  function handleListChange(event) {
    console.log("track presenter:=", values?.[0]?.Presenters_name);
    const { id } = event.target;
    if (id == "profile_image") {
      const file = event.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;

      console.log(fileType, fileSize, "fileSizefileSizefileSize");

      if (
        (fileType === "image/png" ||
          fileType === "image/jpeg" ||
          fileType === "image/jpg" ||
          fileType === "image/webp") &&
        fileSize <= 1024 * 1024 // 1MB in bytes
      ) {
        setEdit_Presenter(false);
        const { files } = event.target;
        setpreviewUrl(URL.createObjectURL(files[0]));
        setnewPresenter({
          ...newPresenter,
          [id]: files[0],
          preview: URL.createObjectURL(files[0]),
        });
      } else {
        toastAlert("error", "The file must be less than 1 MB.");
        return false;
      }
    } else {
      setnewPresenter({ ...newPresenter, [id]: event.target.value });
    }
  }
  const {
    youtubekey,
    paymentkey,
    privatekey,
    firstname,
    lastname,
    emailid,
    facebookkey,
    profile_image,
    telegramkey,
  } = initialValue;

  // OTP Countdown Timer (5 minutes)
  useEffect(() => {
    let countdown;
    if (isOTPSent) {
      countdown = setInterval(() => {
        setTimer((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(countdown);
            setIsOtpSent(false);
            return 0; // Stop the countdown at 0
          }
          return prevTime - 1;
        });
      }, 1000); // Countdown interval of 1 second
    } else {
      setTimer(300); // Reset timer when OTP is not sent
    }

    // Cleanup interval on component unmount or when `isOTPSent` changes
    return () => clearInterval(countdown);
  }, [isOTPSent]);

  // Resend button disable timer (2 minutes)
  useEffect(() => {
    let resendCountdown;
    if (isDisableResend) {
      resendCountdown = setInterval(() => {
        setResendTimer((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(resendCountdown);
            setIsDisableResend(false); // Re-enable the button after 2 minutes
            return 120; // Reset resend timer
          }
          return prevTime - 1;
        });
      }, 1000); // Countdown interval of 1 second
    }

    // Cleanup interval on component unmount or when `isDisableResend` changes
    return () => clearInterval(resendCountdown);
  }, [isDisableResend]);

  useEffect(() => {
    console.log("init", initialValue)
  }, [initialValue])

  // Format timer to MM:SS format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    if (user_deatils) {
      const updatedValues = {
        facebookkey: user_deatils.FaceBook_key,
        youtubekey: user_deatils.YouTube_key,
        telegramkey: user_deatils?.telegram_key,
        paymentkey: user_deatils?.payment_key,
        privatekey: user_deatils?.private_key,
        firstname: user_deatils.userName,
        lastname: user_deatils.lastName,
        emailid: user_deatils.email,
        profile_image: user_deatils?.profile_image,
        stripeAccountId: user_deatils?.stripeAccountId,
      };

      // Only update if there's a change in the values
      if (JSON.stringify(initialValue) !== JSON.stringify(updatedValues)) {
        setintialValue(updatedValues);
      }

      const newProfileImage = user_deatils?.profile_image;
      // Only update profile_image if it's different
      if (newPresenter.profile_image !== newProfileImage) {
        setnewPresenter({ ...newPresenter, profile_image: newProfileImage });
      }
    }
  }, [user_deatils, newPresenter]); // Add newPresenter and initialValue to dependencies


  const getSubcriptionList = async () => {
    const { status, result } = await getSubcriptionLists();
    if (status) {
      console.log("resultsub", result);
      setSubcription(result);
    }
  };

  const updateYoutube = async () => {

    try {
      setLoader(true);
      if (isDisable) {
        return false;
      }

      console.log(streamKey, "streamKeystreamKeystreamKey");
      if (
        streamKey.youtubekey == "" &&
        streamKey.facebookkey == "" &&
        streamKey.telegramkey == ""
      ) {
        toastAlert("error", "Key required");
        return false;
      }

      console.log(streamKey, "streamKey");
      if (
        user_deatils.YouTube_key == streamKey.youtubekey &&
        user_deatils.FaceBook_key == streamKey.facebookkey &&
        user_deatils.telegram_key == streamKey.telegramkey
      ) {
        toastAlert("success", "This Key is already updated");
        return false;
      }

      setIsDisable(true);
      const { result, status } = await updateYoutube_key(streamKey);

      console.log(result, status, "result, status");
      if (status) {
        toastAlert("success", result.message);

        dispatch(getUserDetails());
        setintialValue({
          ...initialValue,
          facebookkey:
            streamKey.facebookkey == ""
              ? user_deatils.FaceBook_key
              : streamKey.facebookkey,
          youtubekey:
            streamKey.youtubekey == ""
              ? user_deatils.YouTube_key
              : streamKey.youtubekey,
          telegramkey:
            streamKey.telegramkey == ""
              ? user_deatils.telegram_key
              : streamKey.telegramkey,
        });
        setIsDisable(false);
        setLoader(false);
      } else {
        toastAlert("error", result.message);
        setIsDisable(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const profileValidation = async () => {
    const error = {};

    console.log(initialValue.emailid, "initialValue");

    // Ensure fields are not empty or undefined
    if (!initialValue.emailid?.trim() && initialValue.emailid == "") {
      error.emailid = "Email is required";
    }
    if (!initialValue.firstname?.trim()) {
      error.firstname = "Firstname is required";
    }
    if (!initialValue.lastname?.trim()) {
      error.lastname = "Lastname is required";
    }

    return error;
  };

  const updateUser = async () => {
    try {
      console.log("isDisable", isDisable);
      if (isDisable) {
        return false;
      }
      console.log("after");
      const error = await profileValidation();

      console.log(error, "error______11111");
      if (Object.keys(error).length !== 0) {
        setError(error);
        return false;
      }
      setIsDisable(true);

      if (isOTPSent) {
        const { result, status } = await verifyOtp(initialValue);
        if (status) {
          toastAlert("success", result.message);
          setIsOtpSent(false);
          dispatch(getUserDetails());
          setIsDisable(false);
        } else {
          toastAlert("error", result.message);
          setIsDisable(false);
        }
      } else {
        const formData = new FormData();

        formData.append("facebookkey", initialValue.facebookkey);
        formData.append("youtubekey", initialValue.youtubekey);
        formData.append("telegramkey", initialValue.telegramkey);
        formData.append("paymentkey", initialValue.paymentkey);
        formData.append("privatekey", initialValue.privatekey);
        formData.append("firstname", initialValue.firstname);
        formData.append("lastname", initialValue.lastname);
        formData.append("emailid", initialValue.emailid);
        formData.append("otp", initialValue.otp);

        // Append the profile image file if it exists
        if (newPresenter.profile_image) {
          formData.append("profile_image", newPresenter.profile_image);
        }
        console.log("formdata", newPresenter);
        const { result, status } = await updateUserDeatails(formData);
        console.log(result, status, "result, status");
        if (status) {
          if (result.isEmailSent) {
            setIsOtpSent(true);
            toastAlert("success", result.message);
            setIsDisable(false);
          } else {
            // userupdated same
            setIsOtpSent(false);
            console.log("setIsOtpSent", setIsOtpSent);
            toastAlert("success", result.message);
            dispatch(getUserDetails());
            setIsDisable(false);
          }
        } else {
          toastAlert("error", result.message);
          setIsDisable(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsDisable(false);
    }
  };

  // Handle Resend OTP
  const resend_otp = async () => {
    try {
      setIsDisableResend(true); // Disable resend button for 2 minutes
      setResendTimer(120); // Start 2-minute countdown
      setTimer(300); // Reset the OTP timer to 5 minutes

      const { result, status } = await sendOtpEmail(initialValue); // Simulate your resend OTP API call
      if (status) {
        toastAlert("success", result.message);
        setIsOtpSent(true); // Start the OTP timer again
      } else {
        toastAlert("error", result.message);
      }
    } catch (error) {
      console.log(error);
      toastAlert("error", "Failed to resend OTP");
      setIsDisableResend(false); // Re-enable button in case of error
    }
  };

  const streamchangeHadler = (e) => {
    const { id, value } = e.target;
    setStreamKey({ ...streamKey, [id]: value });
  };

  const changeHadler = (e) => {
    const { id, value } = e.target;
    console.log(id, value, "----226");
    setintialValue({ ...initialValue, [id]: value });
  };

  //password changes
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);

  const InitialFormValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [formValue, setFormValue] = useState(InitialFormValue);

  const [passmessage, setpassmessage] = useState("");

  //password icon
  const [showOPwd, setShowOPwd] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [showconfPwd, setShowconfPwd] = useState(false);

  const handlePassword = (passwordValue) => {
    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    var errormsg = {};
    if (!strengthChecks.length) {
      errormsg = "Password must contain at least 8 characters";
    } else if (!strengthChecks.hasUpperCase) {
      errormsg = "Password must contain at least one uppercase letter";
    } else if (!strengthChecks.hasLowerCase) {
      errormsg = "Password must contain at least one lowercase letter";
    } else if (!strengthChecks.hasDigit) {
      errormsg = "Password must contain at least one numeric character";
    } else if (!strengthChecks.hasSpecialChar) {
      errormsg = "Password must contain at least one special character";
    }
    setpassmessage(errormsg);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
      verifiedList.length == 5
        ? "Strong"
        : verifiedList.length >= 2
          ? "Medium"
          : "Weak";

    // setPassword(passwordValue);
    // setProgress(`${(verifiedList.length / 5) * 100}%`);
    // setMessage(strength);

    // console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
  };

  const Fromvalidation = async () => {
    try {
      var validateError = {};
      if (formValue.newPassword.trim() == "") {
        validateError.newPassword = "New password is required";
      }

      if (formValue.oldPassword.trim() == "") {
        validateError.oldPassword = "Old password is required";
      } else if (Object.keys(passmessage).length != 0) {
        validateError.newPassword = passmessage;
      }
      if (formValue.confirmPassword.trim() == "") {
        validateError.confirmPassword = "Confirm password is required";
      } else if (formValue.newPassword != formValue.confirmPassword) {
        validateError.confirmPassword =
          "Password and confirm password doesn't match";
      }

      setError(validateError);
      return validateError;
    } catch (err) {
      //console.log(err);
    }
  };

  //model
  const [showPassword, setShowPassword] = useState(false);

  const changesHadler = async (e) => {
    try {
      const { id, value } = e.target;
      console.log(id, value);

      setFormValue({ ...formValue, [id]: value });

      if (id == "newPassword") {
        handlePassword(value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePasswordShow = () => {
    console.log("clik");
    setShowPassword(true);
  };
  const handlePasswordClose = () => setShowPassword(false);

  const submit_Handler = async (e) => {
    try {
      console.log("formValue===", formValue);
      setloading(true);
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      console.log("err si", errorsSize);
      if (errorsSize == 0) {
        setloading(true);
        const { status, result } = await user_resetPassword(formValue);

        if (status) {
          toastAlert("success", result.message);
          clearCookies();
          handlePasswordClose();
        } else {
          setError(result.message);
          toastAlert("error", result.message);
        }
      }
    } catch (error) {
      toastAlert("error", "Something went wrong,please try again later");
    } finally {
      setloading(false);
    }
  };
  //Only View Purpose
  // const package_names = subcription?.plan.map((item) => item.package_name) || [];
  const package_names = subcription?.plan
    ? subcription.plan.map((item) => item.package_name)
    : [];
  const package_amounts = subcription?.result
    ? subcription.result.map((item) => item.PlanAmount)
    : [];
  const package_date = subcription?.result
    ? subcription.result.map((item) => item.NoofDays)
    : [];
  //Date Change
  const package_validatity = subcription?.result
    ? subcription.result.map((item) => item.plan_expired)
    : [];
  const date = new Date(package_validatity);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const localDateString = date.toLocaleString(undefined, options);
  const renewal = () => {
    window.location.href = "/Subscription";
  };
  console.log("isDisable--------", isDisable);
  console.log("isOTPSent", package_names);

 /* ------------------------ Stripe ---------------------------- */

 //create stripe connect acount
  const handleStripeConnect = async () => {
    setStripeLoader(true);
    try {
      const { result, status } = await connectAccount();
      if (status) {
        // window.location.href = result.url;
        window.open(result.url, '_self')
      } else {
        toastAlert("error", result.message);
        setStripeLoader(false);
      }
    } catch (error) {
      toastAlert("error", "Failed to connect Stripe account");
      console.error("Error connecting Stripe account:", error);
      setStripeLoader(false);
    }
    // finally {
    //   setStripeLoader(false);
    // }
  };


  useEffect(() => {
    checkBankDetailss()
  }, [bankConnected])

  //check stripe bankdetails 
  const checkBankDetailss = async () => {
    try {
      const { status, result } = await checkBankDetails();
      console.log("check bankdet", status, result)

      if (status) {
        setStripeAccountId(result.stripeAccountId);
        setBankConnected(true);
      }
    } catch (error) {
      toastAlert("error", error);
      console.error(error);
    }
  };

  //Disconnect stripe acount
  const disconnectStripeAccount = () => {
    setShowConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const submitDisconnect = async (id) => {
    setStripeLoader(true);
    const { result, status } = await disconnectAccount();
    if (status) {
      toastAlert("success", result.message);
      console.log("Stripe account disconnected successfully:", result);
      window.location.reload();
    }
    setShowConfirmationModal(false);
    setStripeLoader(false);
  };

  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>
      <div className="dashboard-page">
        <div className="container">
          <div className="page-flex">
            <div className="page-left">
              <Innerpagenavbar />
            </div>
            <div className="page-right">
              <div className="dashbord-right">
                <div className="dashbord-right-body-1 dashboard-padding">
                  <div className="dashbord-right-header">
                    <p>My Profile</p>
                  </div>
                  <div className="inner_home_shadow blue_shade">
                    <div className=" inner_settings_shadow">
                      <div className="inner_settings_head">
                        <p>My Subscription</p>
                      </div>
                      <div className="hrline"></div>
                      <div className="blue_flex">
                        {package_names && package_names.length > 0 ? (
                          <>
                            <div className="blue_flex_sub mb-2">
                              <div>
                                <p>
                                  <span>{package_names}</span>
                                </p>
                                <div className="blue_box">
                                  <p>
                                    <span> ${package_amounts}</span>/
                                    {package_date} Days Access
                                  </p>
                                </div>
                              </div>
                              <button
                                to="#"
                                className="primary_btn"
                                onClick={renewal}
                              >
                                Renewal
                              </button>
                            </div>
                            <p>
                              Next payment: <span>{localDateString}</span>
                            </p>
                          </>
                        ) : (
                          <p>Please subscribe to the plan</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="inner_home_shadow">
                    <div className=" inner_settings_shadow">
                      <div className="inner_settings_head">
                        <p>Contact details</p>
                        <button
                          to="#"
                          className="primary_btn "
                          onClick={updateUser}
                        >
                          Change
                        </button>
                      </div>
                      <div className="hrline hrline2"></div>
                      <Row className="mt-4  align-items-center inner_fields inner_fields1">
                        <Col xxl="1" xl="2" lg="2" className="mb-3 mb-lg-3">
                          {" "}
                          <div className="error_input">
                            <div className="img_position_basic">
                              <Form.Control
                                ref={fileRef}
                                id="profile_image"
                                type="file"
                                className="img_upload"
                                accept=".png,.jpg,.jpeg,.webp"
                                // value={profile_image}
                                onChange={handleListChange}
                              // disabled={!edit_presenter}
                              />
                              {/* <i class="bi bi-folder-plus upload"  style={{ fontSize: '30px' }}></i> */}
                              {previewUrl != undefined && previewUrl != "" ? (
                                <img
                                  src={previewUrl}
                                  onClick={() => fileRef.current.click()}
                                  className="img-fluid "
                                  style={{
                                    height: "60px",
                                    width: "60px",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                />
                              ) : profile_image != undefined &&
                                profile_image != "" ? (
                                <img
                                  src={`${key.API_URL}/presenter/${profile_image}`}
                                  onClick={() => fileRef.current.click()}
                                  className="img-fluid "
                                  style={{
                                    height: "60px",
                                    width: "60px",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                />
                              ) : (
                                <>
                                  <img
                                    src={require("../assets/images/profile-img2.png")}
                                    onClick={() => fileRef.current.click()}
                                    className="img-fluid image-dp"
                                    style={{
                                      height: "60px",
                                      width: "60px",
                                      borderRadius: "50%",
                                      cursor: "pointer",
                                    }}
                                  />

                                  {/* {error && error.image && <span style={{ color: 'red' }}>{error.image}</span>} */}
                                </>
                              )}
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Upload image
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={require("../assets/images/edit.png")}
                                  className="image_edit"
                                  onClick={() => fileRef.current.click()}
                                />
                              </OverlayTrigger>
                            </div>
                            <div className="error_msg ">
                              {error && error.firstname && (
                                <span style={{ color: "red" }}>
                                  {error.firstname}
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col xxl="3" xl="4" lg="4" className="mb-3 mb-lg-3">
                          {" "}
                          <div className="error_input">
                            <InputGroup className=" input-box-1 ">
                              <Form.Control
                                placeholder="First name"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                value={firstname}
                                id="firstname"
                                onChange={changeHadler}
                              />
                            </InputGroup>

                            <div className="error_msg ">
                              {error && error.firstname && (
                                <span style={{ color: "red" }}>
                                  {error.firstname}
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col xxl="3" xl="3" lg="3" className="mb-3 mb-lg-3">
                          {" "}
                          <div className="error_input">
                            <InputGroup className=" input-box-1 ">
                              <Form.Control
                                placeholder="Last name"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                value={lastname}
                                id="lastname"
                                onChange={changeHadler}
                              />
                            </InputGroup>

                            <div className="error_msg ">
                              {error && error.lastname && (
                                <span style={{ color: "red" }}>
                                  {error.lastname}
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col xxl="3" xl="3" lg="3" className="mb-3 mb-lg-3">
                          {" "}
                          <div className="error_input">
                            <InputGroup className=" input-box-1 ">
                              <Form.Control
                                placeholder="Email address"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                value={emailid}
                                id="emailid"
                                onChange={(e) => changeHadler(e)}
                              />
                            </InputGroup>
                            <div className="error_msg ">
                              {error && error.emailid && (
                                <span style={{ color: "red" }}>
                                  {error.emailid}
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col
                          xxl="2"
                          xl="3"
                          lg="3"
                          className="mb-1 ps-0 text-end button-end mb-md-3"
                        >
                          {" "}
                          <button to="#" className="primary_btn " onClick={updateUser}>
                            Update
                          </button>
                        </Col>
                      </Row>
                      <div className="inner_fields ">
                        {isOTPSent && (
                          <>
                            <InputGroup className="input-box-1">
                              <Form.Control
                                type="number"
                                placeholder="OTP"
                                aria-label="OTP"
                                aria-describedby="basic-addon2"
                                id="otp"
                                onChange={changeHadler}
                              />

                              <InputGroup.Text
                                style={{ fontSize: "14px", color: "#001221" }}
                              >
                                {formatTime(timer)}{" "}
                                {/* Display the OTP countdown */}
                              </InputGroup.Text>
                            </InputGroup>

                            {/* Resend Button (only show after 2 minutes) */}
                            {!isDisableResend && (
                              <button
                                className="primary_btn"
                                onClick={resend_otp}
                              >
                                Resend {/* Show only when not disabled */}
                              </button>
                            )}

                            {/* {isDisableResend && (
                          <p> {formatTime(resendTimer)}</p>
                        )} */}
                          </>
                        )}
                      </div>
                    </div>

                    <div className=" inner_settings_shadow">
                      <div className="inner_settings_head">
                        <p>Sign In </p>
                      </div>

                      {/* <div className="inner_settings_details mt-4">
                    <p>Sign-In Email</p>
                    <span>sat***@gmail.com</span>
                    <Link to="#" className="primary_btn">
                      Edit
                    </Link>
                  </div> */}
                      <div className="inner_settings_details mt-3">
                        <p>Sign-In Password</p>

                        <Link
                          onClick={handlePasswordShow}
                          className="primary_btn"
                        >
                          Edit
                        </Link>
                      </div>
                    </div>
                    <div className=" inner_settings_shadow ">
                      <div className="inner_settings_head">
                        <p>Payment</p>
                      </div>
                      <div className="inner_settings_details mt-3">
                        <p>Stripe</p>


                        {stripeAccountId && bankConnected ? (
                          stripeLoader ? (
                            <Link to="#" className="primary_btn">
                              <Spinner animation="border" role="status" size="sm">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                              Disconnecting...
                            </Link>
                          ) : (
                            <Link onClick={disconnectStripeAccount} className="primary_btn">
                              Disconnect Stripe Account
                            </Link>
                          )
                        ) : stripeLoader ? (
                          <Link to="#" className="primary_btn">
                            <Spinner animation="border" role="status" size="sm">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            Connecting...
                          </Link>
                        ) : (
                          <Link onClick={handleStripeConnect} className="primary_btn">
                            Connect Stripe Account
                          </Link>
                        )}

                      </div>
                    </div>


                    <div className=" inner_settings_shadow ">
                      <div className="inner_settings_head">
                        <p> Streaming Key </p>
                      </div>
                      <div className=" mt-3">
                        <div className="row">
                          <div className="col-lg-12 mb-2">
                            <div className="settings_display ">
                              {youtubekey == "" ? (
                                <Row className="inner_profile_settings">
                                  <Col lg="12" md="12" sm="12" className="  ">
                                    <Row>
                                      <Col
                                        lg="12"
                                        className="profile_info mb-3"
                                      >
                                        <Form.Label>
                                          <img
                                            src={require("../assets/images/u_tube.png")}
                                            className="img-fluid me-2"
                                          />
                                          YouTube Streaming Key
                                        </Form.Label>
                                        <div className="reset_code1">
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter YouTube key"
                                            id="youtubekey"
                                            onChange={streamchangeHadler}
                                          />
                                          <div>
                                            <Link
                                              to="#"
                                              className="primary_btn"
                                              onClick={updateYoutube}
                                            >
                                              Update
                                            </Link>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              ) : (
                                <div className="inner_profile_settings">
                                  <div className="row">
                                    <div className="col-lg-6"></div>
                                  </div>
                                  <div className="reset_code">
                                    <div>
                                      <img
                                        src={require("../assets/images/u_tube.png")}
                                        className="img-fluid"
                                      />
                                      <div>
                                        <h2>YouTube Streaming Key</h2>
                                      </div>
                                    </div>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          Sync your YouTube channel's streaming
                                          key on the platform to go live
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src={require("../assets/images/icon_4.png")}
                                        className="img-fluid"
                                      />
                                    </OverlayTrigger>
                                  </div>
                                  <div className="reset_code1">
                                    <h4>{youtubekey}</h4>
                                    {/* <h4>jsuc-bcyr-vmz9-31xm-fd2s</h4> */}
                                    <button
                                      className="primary_btn2"
                                      onClick={() =>
                                        setintialValue({
                                          ...initialValue,
                                          youtubekey: "",
                                        })
                                      }
                                    >
                                      Reset code
                                    </button>
                                  </div>
                                  {/* <h5 className="">{youtubekey}</h5> */}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 mb-2">
                            <div className="settings_display ">
                              {facebookkey == "" ? (
                                <Row className="inner_profile_settings">
                                  <Col
                                    lg="12"
                                    md="12"
                                    sm="12"
                                    className=" m-auto "
                                  >
                                    <Row>
                                      <Col
                                        lg="12"
                                        className="profile_info mb-3"
                                      >
                                        <Form.Label>
                                          {" "}
                                          <img
                                            src={require("../assets/images/facebook-1.png")}
                                            className="img-fluid me-2"
                                          />
                                          Facebook streaming key
                                        </Form.Label>
                                        <div className="reset_code1">
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Facebook key "
                                            id="facebookkey"
                                            onChange={streamchangeHadler}
                                          />
                                          <div>
                                            <Link
                                              to="#"
                                              className="primary_btn"
                                              onClick={updateYoutube}
                                            >
                                              Update
                                            </Link>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              ) : (
                                <div className="inner_profile_settings">
                                  <div className="reset_code">
                                    <div>
                                      <img
                                        src={require("../assets/images/facebook-1.png")}
                                        className="img-fluid"
                                      />
                                      <div>
                                        <h2> Facebook Streaming Key</h2>
                                      </div>
                                    </div>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          Sync your Facebook channel's streaming
                                          key on the platform to start
                                          streaming.
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src={require("../assets/images/icon_4.png")}
                                        className="img-fluid"
                                      />
                                    </OverlayTrigger>
                                  </div>
                                  <div className="reset_code1">
                                    <h4>
                                      {facebookkey}
                                      {/* FB-8623819881014948-0-Abz1YzkcSe3AxEy3 */}
                                    </h4>

                                    <button
                                      className="primary_btn2"
                                      onClick={() =>
                                        setintialValue({
                                          ...initialValue,
                                          facebookkey: "",
                                        })
                                      }
                                    >
                                      Reset code
                                    </button>
                                  </div>
                                  {/* <h5 className="">{facebookkey}</h5> */}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 mb-2">
                            <div className="settings_display ">
                              {telegramkey == "" ? (
                                <Row className="inner_profile_settings">
                                  <Col lg="12" md="12" sm="12" className="  ">
                                    <Row>
                                      <Col
                                        lg="12"
                                        className="profile_info mb-3"
                                      >
                                        <Form.Label>
                                          <img
                                            src={require("../assets/images/telegram.png")}
                                            className="img-fluid me-2"
                                          />
                                          Telegram Bot Token
                                        </Form.Label>
                                        <div className="reset_code1">
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter telegram bot token"
                                            id="telegramkey"
                                            onChange={streamchangeHadler}
                                          />

                                          <div className="text-end">
                                            {(loader) ? (
                                              <Link
                                                to="#"
                                                className="primary_btn"
                                              >
                                                <Spinner animation="border" role="status" size="sm">
                                                  <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                Update
                                              </Link>
                                            ) : (
                                              <Link
                                                to="#"
                                                className="primary_btn"
                                                onClick={updateYoutube}
                                              >
                                                Update
                                              </Link>
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              ) : (
                                <div className="inner_profile_settings">
                                  <div className="row">
                                    <div className="col-lg-6"></div>
                                  </div>
                                  <div className="reset_code">
                                    <div>
                                      <img
                                        src={require("../assets/images/telegram.png")}
                                        className="img-fluid"
                                      />
                                      <div>
                                        <h2>Telegram Bot Token</h2>
                                      </div>
                                    </div>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          Sync your Telegram channel's key on
                                          the platform to instantly share links.
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src={require("../assets/images/icon_4.png")}
                                        className="img-fluid"
                                      />
                                    </OverlayTrigger>
                                  </div>
                                  <div className="reset_code1">
                                    <h4>
                                      {telegramkey}
                                      {/* 7140307653:AAF7RR4QGAZwV4wKFoK8jPHpdV8Rp8DxCps */}
                                    </h4>

                                    <button
                                      className="primary_btn2"
                                      onClick={() =>
                                        setintialValue({
                                          ...initialValue,
                                          telegramkey: "",
                                        })
                                      }
                                    >
                                      Reset code
                                    </button>
                                  </div>
                                  {/* <h5 className="">{telegramkey}</h5> */}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={showPassword} onHide={handlePasswordClose}>
          <Modal.Header className="model-head" closeButton>
            <Modal.Title className="model-title">Change Your Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="12">
                <div className="text-box">
                  <label for="exampleFormControlInput1" class="form-label">
                    Old Password <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="inner-accMain">
                    <InputGroup className="mb-3 input-box-1 ">
                      <Form.Control
                        type={showOPwd ? "text" : "password"}
                        class="form-control"
                        id="oldPassword"
                        placeholder="Enter your Old Password"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={changesHadler}
                      />

                      <InputGroup.Text id="basic-addon2">
                        {showOPwd ? (
                          <i
                            className="fa-regular fa-eye"
                            onClick={() => setShowOPwd(false)}
                          ></i>
                        ) : (
                          <i
                            className="fa-regular fa-eye-slash"
                            onClick={() => setShowOPwd(true)}
                          ></i>
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                    {error && error.oldPassword ? (
                      <span style={{ color: "red" }}>{error.oldPassword}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <div className="text-box">
                  <label for="exampleFormControlInput1" class="form-label">
                    New Password <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="inner-accMain">
                    <InputGroup className="mb-3 input-box-1 ">
                      <Form.Control
                        type={showPwd ? "text" : "password"}
                        class="form-control"
                        id="newPassword"
                        placeholder="Enter your New Password"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={changesHadler}
                      />

                      <InputGroup.Text id="basic-addon2">
                        {showPwd ? (
                          <i
                            className="fa-regular fa-eye"
                            onClick={() => setShowPwd(false)}
                          ></i>
                        ) : (
                          <i
                            className="fa-regular fa-eye-slash"
                            onClick={() => setShowPwd(true)}
                          ></i>
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                    {error && error.newPassword ? (
                      <span style={{ color: "red" }}>{error.newPassword}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <div className="text-box">
                  <label for="exampleFormControlInput1" class="form-label">
                    Confirm Password <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="inner-accMain">
                    <InputGroup className="mb-3 input-box-1 ">
                      <Form.Control
                        type={showconfPwd ? "text" : "password"}
                        class="form-control"
                        id="confirmPassword"
                        placeholder="Enter your Confirm Password"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={changesHadler}
                      />

                      <InputGroup.Text id="basic-addon2">
                        {showconfPwd ? (
                          <i
                            className="fa-regular fa-eye"
                            onClick={() => setShowconfPwd(false)}
                          ></i>
                        ) : (
                          <i
                            className="fa-regular fa-eye-slash"
                            onClick={() => setShowconfPwd(true)}
                          ></i>
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  {error && error.confirmPassword ? (
                    <span style={{ color: "red" }}>
                      {error.confirmPassword}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Link
              className="primary_btn"
              onClick={() => submit_Handler(formValue)}
            >
              Submit
            </Link>
          </Modal.Footer>
        </Modal>

        {/*Delete Confirmation modal*/}
        <ConfirmationModal
          showModal={showConfirmationModal}
          confirmModal={submitDisconnect}
          hideModal={hideConfirmationModal}
          config={confirmationConfig}
          loader={loader}
        />
      </div >
      <Innerfooter />
    </>
  );
};
export default InnerSettings;
